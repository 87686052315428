import { Container, Navbar, Nav } from 'react-bootstrap';
import heroImage from '../_images/radiation_knobs.jpg';
import React from 'react';
import logo from '../_images/atomidevs_logo.png';

function NavBarSmart() {


  const styles = {

    componentBackground: {
        height: "100vh",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        backgroundImage: `url(${heroImage})`, 
        backgroundAttachment: "fixed",
        minWidth: "100%",
        // textAlign: "center",
    },      

    componentPage: {
        height: "100%",
    },

    noBorder: {
        border: "0px",
        height: "100%",
        textAlign: "center",
        margin: "0 auto",
        float: "none",
        backgroundColor: "#ccc",
    }, 

    card: {
        backgroundColor: '#dae0e3',
        borderRadius: 15,
        // padding: '2rem',
        opacity: "1",
        border: "0px"
    },

    containMe: {
        maxWidth: "100%",
        margin: "0px",
        padding: "0px",
    }


  }

  return (
    <>
      <Navbar bg="dark" variant="dark" collapseOnSelect expand="lg"  >
        <Container >
          <Navbar.Brand href="https://atomidevs.com" >
            <img alt="Atomidevs" src={logo}  className="d-inline-block align-top" />{' '}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav"  />
          <Navbar.Collapse id="responsive-navbar-nav" >
          
          <Nav style={{marginLeft: 'auto'}}  >

            <Nav.Link href="https://atomidevs.com/" style={styles.styleLink}>
              HOME
            </Nav.Link>

          </Nav>

          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );

}

export default NavBarSmart;