import { Button, Form, Card, Row, Col, Container } from 'react-bootstrap';
import heroImage from '../_images/radiation_knobs.jpg';
import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import axios from 'axios';
import NavBarSmart from '../_components/NavBarSmart';

function UnsubscribePage() {

    const [showUnsubscribed, setShowUnsubscribed] = useState('false');
    const [searchParams] = useSearchParams();
    const [emailValue, setEmailValue] = useState('');


    const styles = {

        componentBackground: {
            height: "100vh",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: "relative",
            backgroundImage: `url(${heroImage})`,
            backgroundAttachment: "fixed",
            margin: "0px",
        },

        componentBackgroundMobile: {
            background: "#000",
            margin: "0px",
            paddingBottom: "40px",
        },

        componentPage: {
            height: "100%",
        },

        noBorder: {
            border: "0px",
            height: "100%",
            textAlign: "center",
            margin: "0 auto",
            float: "none",
            backgroundColor: "#ccc",
        },

        card: {
            backgroundColor: '#dae0e3',
            borderRadius: 15,
            // padding: '2rem',
            opacity: "1",
            border: "0px"
        },

        containMe: {
            maxWidth: "100%",
            margin: "0px",
            padding: "0px",
        },


        fullHeight: {
            hegiht: "100%",
        },
    }

    const unsubscribeMe = async (e) => {
        e.preventDefault();
        e.target.sendButton.disabled = true;
        // console.log('sending unsubscribe');

        const submitItems = {
            email: e.target.email.value,
        }

        // console.log(submitItems)

        try {
            const response = await axios.post('https://app.atomidevs.com/cst/ws/atomi/unsubscribe', submitItems)
                .then((response) => {
                    // console.log(response.data)
                    setShowUnsubscribed('true');
                });
            return response;

        } catch (err) {
            console.log(err);
        }

        // console.log('enabled');
        e.target.sendButton.disabled = false;

    }


    useEffect(() => {
        // get the user's email from url
        if (searchParams.get("email") != null){
            setEmailValue(searchParams.get("email"));
        } else {
            setEmailValue('');
        }
        
            //console.log(emailValue);

    },[searchParams]);

    
    
    return (


        <div>
            <NavBarSmart />

            {/* unsubscribe form */}
            {showUnsubscribed === 'false' && (
                <Container style={styles.containMe}>
                    <Row style={styles.componentBackground}>
                        <Col>
                            <div style={styles.componentPage}>
                                <div style={styles.fullHeight}>
                                    <div>

                                        <Card style={styles.noBorder} >
                                            <Card.ImgOverlay>
                                                <Card.Body style={styles.card}>
                                                    <Form className={styles.formContainer} onSubmit={(e) => unsubscribeMe(e)}>
                                                        <Form.Group >
                                                            <Row>
                                                                <h3>You're leaving us?&nbsp;&nbsp;I thought we were friends?</h3>
                                                                <br/>
                                                                <p>We get it.  Nobody wants noise.  We hope we can help you in the future!</p>

                                                                <Col xs={0} md={3}>&nbsp;</Col>
                                                                <Col xs={12} md={6} offset={3}>
                                                                    <br />&nbsp;
                                                                    <Form.Label>Your Email</Form.Label>
                                                                    <Form.Control type="email" id="email" value={emailValue} onChange={(e) => setEmailValue(e.target.value)} />
                                                                    <br />&nbsp;
                                                                    <br />
                                                                    <div className="d-grid gap-2">
                                                                        <Button id="sendButton" variant="primary" size="lg" type="submit" >
                                                                            Unsubscribe Me
                                                                        </Button>
                                                                        <br />
                                                                    </div>
                                                                </Col>

                                                            </Row>

                                                        </Form.Group>


                                                    </Form>
                                                </Card.Body>
                                            </Card.ImgOverlay>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            )}


            {/* after submission  */}
            {showUnsubscribed === 'true' && (
                <Container style={styles.containMe}>
                    <Row  style={styles.componentBackground}>
                        <Col>
                            <div style={styles.componentPage}>
                                <div style={styles.fullHeight}>
                                    <div>

                                        <Card style={styles.noBorder}>
                                            <Card.ImgOverlay>
                                                <Card.Body style={styles.card}>
                                                    <Card.Title className="text-center">
                                                        We miss you already!  You've been unsubscribed.
                                                        <br />&nbsp;<br />
                                                        <a href="https://atomidevs.com" target="_self" rel="noreferrer">
                                                            <Button id="homeButton" variant="primary" size="lg" >
                                                                Home
                                                            </Button>
                                                        </a>
                                                    </Card.Title>

                                                </Card.Body>
                                            </Card.ImgOverlay>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </Col></Row>
                </Container>
            )}


        </div>
    );
}

export default UnsubscribePage;
