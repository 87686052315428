// import '../App.css';
import { Button, Form, Card, Row, Col, Container } from 'react-bootstrap';
import heroImage from '../_images/radiation_knobs.jpg';
import React, { useState } from 'react';
import axios from 'axios';
import NavBarSmart from '../_components/NavBarSmart';

function ContactPage() {

    const [showSuccess, setShowSuccess] = useState('false');

    const styles = {

        componentBackground: {
            height: "100vh",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: "relative",
            backgroundImage: `url(${heroImage})`,
            backgroundAttachment: "fixed",
            margin: "0px",
        },      

        componentBackgroundMobile: {
            background: "#000",
            margin: "0px",
            paddingBottom: "40px",
        },  

        componentPage: {
            height: "100%",
        },
    
        noBorder: {
            border: "0px",
            height: "100%",
            textAlign: "center",
            margin: "0 auto",
            float: "none",
            backgroundColor: "#ccc",
        }, 

        card: {
            backgroundColor: '#dae0e3',
            borderRadius: 15,
            // padding: '2rem',
            opacity: "1",
            border: "0px"
        },

        containMe: {
            maxWidth: "100%",
            margin: "0px",
            padding: "0px",
        },


        fullHeight: {
            hegiht: "100%",
        },
    }


    const sendMessage = async(e) => {
        e.preventDefault();
        e.target.sendButton.disabled=true;
        // console.log('send message');

        const submitItems = {
            name: e.target.fullName.value,
            number: e.target.phone.value,
            email: e.target.email.value,
            message: e.target.help.value
        }

        // console.log(submitItems)
 
        try {
            const response = await axios.post('https://app.atomidevs.com/cst/ws/atomi/contact', submitItems)
                .then( (response)  => {
                    console.log(response.data)
                    setShowSuccess('true');
                });
                return response;
        
        } catch (err) {
            console.log(err);
        }

        // console.log('enabled');
        e.target.sendButton.disabled=false;
        
    }

    return (

    
    <div>
        <NavBarSmart/>

        {/* submission form */}
        {showSuccess ==='false' && (
            <Container style={styles.containMe}>
                {/* desktop */}
                <Row className="d-none d-sm-block" style={styles.componentBackground}>
                    <Col>
                    <div style={styles.componentPage}>
                        <div style={styles.fullHeight}>
                            <div className="col-xs-12 offset-xs-0 col-md-6 offset-md-3">

                                    <Card style={styles.noBorder}>
                                        <Card.ImgOverlay>
                                            <Card.Body style={styles.card}>
                                                <Form className={styles.formContainer} onSubmit={(e) => sendMessage(e)}>
                                                    <Form.Group className="mb-3" >
                                                        <Row>
                                                            {/* left side of card */}
                                                            <Col xs={12} md={6} style={{padding:"20px", borderRight: "2px solid #aaa"}}>
                                                                <h4>Drop a Note</h4>
                                                                
                                                                <br/>
                                                                <Form.Label>Your Name</Form.Label>
                                                                <Form.Control type="text" id="fullName" placeholder="First Last" />

                                                                <br/>
                                                                <Form.Label>Your Email</Form.Label>
                                                                <Form.Control type="email" id="email" placeholder="email@address.com" />

                                                                <br/>
                                                                <Form.Label>Your Phone</Form.Label>
                                                                <Form.Control type="text" id="phone" placeholder="(937) 555-1212" />

                                                                <br/>
                                                                <Form.Label>How can we help?</Form.Label>
                                                                <Form.Select id="help" >
                                                                    <option>I need advice.</option> 
                                                                    <option>I need a developer.</option> 
                                                                    <option>Let's talk.</option> 
                                                                </Form.Select>
                                                                

                                                                <br/>
                                                                <br/>
                                                                <div className="d-grid gap-2">
                                                                    <Button id="sendButton" variant="primary" size="lg" type="submit" >
                                                                        Send Message
                                                                    </Button>
                                                                    <br/>
                                                                </div>

                                                            </Col>

                                                            <Col xs={12} md={6} offset={6} style={{padding:"20px", borderLeft: "3px solid #bbb"}}>
                                                                <h4>Our Info</h4>

                                                                <br/>
                                                                <br/>
                                                                <h6>Customer Inquries</h6>
                                                                
                                                                    partner@atomidevs.com<br/>
                                                                

                                                                <br/>
                                                                <br/>
                                                                <h6>Employment Inquries</h6>
                                                                
                                                                    smarties@atomidevs.com<br/>
                                                                

                                                                <br/>
                                                                <br/>
                                                                <h6>Partner Support Inquries</h6>
                                                                
                                                                    support@atomidevs.com<br/>
                                                                
                                                                <br/>
                                                                <br/>
                                                                <h6>Our Mailing Address</h6>
                                                                    411 Wolfe Ledges Pakrway, Suite 201<br/>
                                                                    Akron, OH 44311<br/>


                                                            </Col>
                                                        </Row>

                                                    </Form.Group>


                                                </Form>
                                            </Card.Body>
                                        </Card.ImgOverlay>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                {/* mobile */}
                <Row className="d-xs-block d-sm-none" style={styles.componentBackgroundMobile}>
                    <div style={styles.fullHeight}>
                        <Card style={styles.noBorder}>
                            <Card.Body style={styles.card}>
                                {/* <Card.Title className="text-center">
                                    Apply now!  It's simple and free!  It takes less than a minute.
                                </Card.Title> */}
                                <Form className={styles.formContainer} onSubmit={(e) => this.saveApplication(e)}>
                                    <Form.Group className="mb-3" >
                                        <h4>Drop a Note</h4>
                                                                                    
                                        <br/>
                                        <Form.Label>Your Name</Form.Label>
                                        <Form.Control type="text" id="fullName" placeholder="First Last" />

                                        <br/>
                                        <Form.Label>Your Email</Form.Label>
                                        <Form.Control type="email" id="email" placeholder="email@address.com" />

                                        <br/>
                                        <Form.Label>Your Phone</Form.Label>
                                        <Form.Control type="text" id="phone" placeholder="(937) 555-1212" />

                                        <br/>
                                        <Form.Label>How can we help?</Form.Label>
                                        <Form.Select id="help" >
                                            <option>I need advice.</option> 
                                            <option>I need a developer.</option> 
                                            <option>Let's talk.</option> 
                                        </Form.Select>

                                        <br/>
                                        <br/>
                                        <div className="d-grid gap-2">
                                            <Button id="sendButton" variant="primary" size="lg" type="submit" >
                                                Send Message
                                            </Button>
                                            <br/>
                                        </div>

                                        <hr/>
                                        
                                            <h4>Our Info</h4>


                                            <br/>
                                            <h6>Customer Inquries</h6>
                                            
                                                partner@atomidevs.com<br/>
                                            

                                            <br/>
                                            <h6>Employment Inquries</h6>
                                            
                                                smarties@atomidevs.com<br/>
                                            

                                            <br/>
                                            <h6>Partner Support Inquries</h6>
                                            
                                                support@atomidevs.com<br/>

                                            <br/>
                                            <h6>Mailing Address Info</h6>
                                            
                                            AtomiDevs, LLC<br/>
                                            411 Wolfe Ledges Pakrway, Suite 201<br/>
                                            Akron, OH 44311<br/>
                                        

                                </Form.Group>
                            </Form>
                        </Card.Body>
                        </Card>
                    </div>
                </Row>
            </Container>
        )}

        {/* after submission  */}
        {showSuccess==='true' && (
            <Container style={styles.containMe}>
                <Row className="d-none d-sm-block" style={styles.componentBackground}><Col>
                    <div style={styles.componentPage}>
                        <div style={styles.fullHeight}>
                            <div className="col-xs-12 offset-xs-0 col-md-6 offset-md-3">

                                <Card style={styles.noBorder}>
                                    <Card.ImgOverlay>
                                        <Card.Body style={styles.card}>
                                            <Card.Title className="text-center">
                                                Message Received.  We'll reach out to you shortly! 
                                                <br/><br/>
                                                <a href="https://atomidevs.com" target="_self" rel="noreferrer">
                                                    <Button id="homeButton" variant="primary" size="lg" >
                                                        Home
                                                    </Button>
                                                </a>
                                            </Card.Title>

                                        </Card.Body>
                                    </Card.ImgOverlay>
                                </Card>
                            </div>
                        </div>
                    </div>
                </Col></Row>

                <Row className="d-xs-block d-sm-none" style={styles.componentBackground} >
                    <div style={styles.componentPage}>
                        <div style={styles.fullHeight}>
                        <br/><br/>
                        <Card style={styles.noBorder}>
                                {/* <Card.ImgOverlay> */}
                                <Card.Body style={styles.card}>
                                    <Card.Title className="text-center">
                                        Message Received.  We'll reach out to you shortly! 
                                        <br/><br/>
                                        <a href="https://atomidevs.com" target="_self" rel="noreferrer">
                                            <Button id="homeButton" variant="primary" size="lg" >
                                                Home
                                            </Button>
                                        </a>
                                    </Card.Title>

                                </Card.Body>
                            {/* </Card.ImgOverlay> */}
                        </Card>
                        </div>
                    </div>
                </Row>
            </Container>
        )}


    </div>
  );
}

export default ContactPage;