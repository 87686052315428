// import logo from './logo.svg';
import './App.css';
// import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ContactPage from './_pages/ContactPage';
import UnsubscribePage from './_pages/UnsubscribePage';

function App() {
  return (

      <BrowserRouter>
        <Routes>
          <Route>          
            <Route exact path="/" element={<ContactPage />}>
            </Route>
            <Route path="/unsubscribe" element={<UnsubscribePage />}>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>

  );
}

export default App;
